<template>
  <div class="order-detail-page">
    <vanTitle title="订单详情" :url="state.url" v-if="state.source == ''"></vanTitle>
    <vanTitle title="订单详情" :go="state.orderInfo.pay_status == '0' ? -2 : -1" v-else-if="state.browser == '1'"></vanTitle>
    <vanTitle title="订单详情" v-else></vanTitle>
    <div class="page-info">
      <div class="info-hd" @click="toKechDetail">
        <div class="hd-tit title">{{state.orderInfo.order_name}}</div>
        <div class="hd-status" :class="{'status-01': state.orderInfo.pay_status == '1', 'status-02': state.orderInfo.pay_status == '0'}">{{state.orderInfo.pay_status_val}}</div>
      </div>
      <div class="hd-number" @click.stop="copy(state.orderInfo.order_sn)">订单号: {{state.orderInfo.order_sn}} <i class="icon-copy"></i></div>
      <div class="hd-price price"><span>¥</span>{{state.orderInfo.amount}}</div>
    </div>
    <div class="page-site" v-if="state.orderInfo.receive_name !== ''">
      <div class="site-item">
        <div class="tit">收货姓名：</div>
        <div class="txt">{{state.orderInfo.receive_name}}</div>
      </div>
      <div class="site-item">
        <div class="tit">收货电话：</div>
        <div class="txt">{{state.orderInfo.mobile}}</div>
      </div>
      <div class="site-item">
        <div class="tit">收货地址：</div>
        <div class="txt">{{state.orderInfo.receive_address}}</div>
      </div>
    </div>
    <div class="btn-wrap">
      <!-- show_mark 1:修改地址 2:查看物流  -->
      <div class="btn btn-01" @click.stop="getWuliu(state.orderInfo.id)" v-if="state.orderInfo.show_mark == 2">查看物流</div>
      <div class="btn btn-01" @click="changeAddress" v-if="state.orderInfo.show_mark == 1">修改地址</div>
    </div>
    <div class="page-site">
      <div class="site-item" v-if="state.orderInfo.create_time">
        <div class="tit">创建时间：</div>
        <div class="txt">{{state.orderInfo.create_time}}</div>
      </div>
      <div class="site-item" v-if="state.orderInfo.paid_time">
        <div class="tit">支付时间：</div>
        <div class="txt">{{state.orderInfo.paid_time}}</div>
      </div>
      <div class="site-item" v-if="state.orderInfo.refund_time">
        <div class="tit">退款时间：</div>
        <div class="txt">{{state.orderInfo.refund_time}}</div>
      </div>
      <div class="site-item" v-if="state.orderInfo.cancel_time && state.orderInfo.pay_status == '2'">
        <div class="tit">取消时间：</div>
        <div class="txt">{{state.orderInfo.cancel_time}}</div>
      </div>
    </div>
    <div class="page-pay" v-if="state.orderInfo.pay_status == '0'">
      <div class="item">
        <div class="lt-tit">支付方式</div>
        <div class="rt" v-if="state.browser == '1'">
          <i class="icon-pay icon-wx"></i>
          <span>微信支付</span>
        </div>
        <div class="rt" v-else>
          <i class="icon-pay icon-zfb"></i>
          <span>支付宝支付</span>
        </div>
      </div>
    </div>
    <div class="bt-protocol" @click="bindProtocol" v-if="state.orderInfo.pay_status == '0'">
      <i class="icon-selected" v-if="state.is_protocol"></i>
      <i class="icon-unselected" v-else></i>
      <!-- 区分在线付费协议和教育付费协议 -->
      <div class="protocol" v-if="state.orderInfo.platform == '2' || state.orderInfo.platform == '8'">已经阅读并同意<a href="//xue.huatu.com/service/zxAgre.html">《用户付费协议》</a></div>
      <div class="protocol" v-else>已经阅读并同意<a href="//xue.huatu.com/service/payAgre.html">《用户付费协议》</a></div>
    </div>
    <zxqCode :zxq_qrcode="state.zxq_qrcode" :browser="state.browser" ref="zxqQrCode"></zxqCode>
    <footer class="page-footer" v-if="state.orderInfo.pay_status == '0'" @click="submit">
      <div class="ft-price price"><span>¥</span>{{state.orderInfo.amount}}</div>
      <div class="middle-btn middle-btn-01">去支付</div>
    </footer>
    <footer class="page-footer" v-else>
      <div class="lt">
        <!-- <div class="ft-btn" @click.stop="getWuliu(state.orderInfo.id)" v-if="state.orderInfo.need_post == 1 && state.orderInfo.pay_status !== 2">
				<i class="icon-wuliu"></i>
				<span class="txt">物流</span>
			</div> -->
        <!-- 教育客服 -->
        <div class="ft-btn kefu" :class="state.orderInfo && 'kefu_' + state.orderInfo.id" v-if="state.orderInfo.reception_id !== 0 && state.kefuData.number !== '' && (state.orderInfo.platform !== 2 )">
          <i class="icon-kefu"></i>
          <span class="txt">客服</span>
        </div>
        <!-- 在线客服 -->
        <div class="ft-btn kefu" v-if="state.orderInfo.platform == 2 " @click="bindKefu()">
          <i class="icon-kefu"></i>
          <span class="txt">客服</span>
        </div>
      </div>
      <div v-if="state.orderInfo.reception_id !== 0 && state.kefuData.number !== '' || !state.orderInfo.express_id"></div>
      <template v-if="state.orderInfo.order_type !== 2 && state.orderInfo.order_type !== 3 && state.orderInfo.order_type !== 4 && state.orderInfo.order_type !== 5">
        <!-- 拼团结束展示学习，图书不展示学习 -->
        <div class="middle-btn" v-if="state.orderInfo.pay_status == 1 && state.orderInfo.flag == 0 || state.orderInfo.pay_status == 1 && state.orderInfo.flag == 1 && state.orderInfo.group_success == 1" @click="bindBuyPop">去学习</div>
      </template>
      <div class="middle-btn middle-btn-02" v-if="state.orderInfo.pay_status == 2">已取消</div>
      <div class="middle-btn middle-btn-02" v-if="state.orderInfo.pay_status == 3">已退款</div>
    </footer>
    <wuLiu :show="state.show" :wuliuData="state.wuliuData" :wuliu_empty_show="state.wuliu_empty_show" @bindPop="bindPop"></wuLiu>
    <!-- 购买须知 -->
    <buyNotice :show="state.buyShow" :isBuy="true" @bindBuyPop="bindBuyPop" :orderInfo="state.orderInfo"></buyNotice>
    <van-popup v-model:show="state.show_code" round @click-overlay="bindCodePop" @opened="onOpened">
      <div class="pop-code">
        <div class="title">请打开支付宝扫码支付</div>
        <div id="qrcode" ref="qrcode">
          <img src="" alt="">
        </div>
        <div class="small-btn btn">支付成功</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  onMounted,
  getCurrentInstance,
  onBeforeUnmount
} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import wuLiu from '../../components/wuLiu/index.vue'
import buyNotice from '../../components/detail/buynotice.vue'
import {
  commitAddShopId,
  getStore,
  sensorTrack,
  setStore
} from '../../utils/util'
import zxqCode from '../../components/zxqCode/index.vue'
import useClipboard from 'vue-clipboard3'
import md5 from 'js-md5'
import QRCode from 'qrcodejs2' // 引入qrcode
export default {
  name: 'orderDetail',
  components: {
    wuLiu,
    buyNotice,
    zxqCode
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const $route = useRoute()
    const $router = useRouter()
    const zxqQrCode = ref()
    const time = Date.parse(new Date())
    const userInfo = getStore('userInfo')
    const store = useStore()
    const state = reactive({
      orderInfo: [], //订单详情
      kefuData: [], //客服数据
      wuliuData: [], //物流信息
      id: '', //订单ID
      buyShow: false, //购买须知弹窗
      show: false,
      code: '', //wxcode
      browser: '', //1-微信浏览器2-其他浏览器
      is_pc: '', //1-pc端2-移动端
      show_code: false, //code码弹窗
      qrCode: '', //二维码链接
      is_protocol: false, //勾选协议
      url: '/mine', //指定页面url
      source: '', // 页面来源 列表页 落地页
      zxq_qrcode: '', //知小群引流码数据
      wuliu_empty_show: false
    })

    onMounted(() => {
      state.id = $route.params.id
      // 页面来源 列表页 落地页
      if ($route.params.source) {
        state.source = $route.params.source
      }
      getOrderInfo() //获取数据
    })

    // 获取物流
    const getWuliu = (id) => {
      // 如果是待支付的--- start ---
      if (state.orderInfo.multi_express == 1) {
        return toLogistics(id) // 多物流，跳转至订单
      }
      // 如果是待支付的--- end ---
      proxy.$http
        .get('/api/transport/detail', {
          params: {
            order_id: id
          }
        })
        .then((res) => {
          console.log('物流===>', res.data)
          const body = res.data

          if (res.data.code == '200') {
            if (body.data == '') {
              state.wuliu_empty_show = true
            } else {
              state.wuliu_empty_show = false
            }

            for (let i in body.data) {
              body.data[i].date = body.data[i].ftime.slice(0, 5)
              body.data[i].time = body.data[i].ftime.slice(6, 11)
            }
            state.wuliuData = body
            state.show = true
          } else {
            proxy.$toast(body.message)
          }
        })
        .catch((error) => console.log(error))
    }
    const toLogistics = (id) => {
      $router.push({
        name: 'orderLogistics',
        params: {
          id
        }
      })
    }
    const bindPop = () => {
      state.show = false
    }

    // 复制订单号
    const { toClipboard } = useClipboard()
    const copy = async (Msg) => {
      try {
        //复制
        await toClipboard(Msg)
        proxy.$toast('复制成功')
      } catch (e) {
        //复制失败
        proxy.$toast('复制失败')
      }
    }

    // 获取订单详情
    const getOrderInfo = () => {
      proxy.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '加载中...'
      })
      proxy.$http
        .get('/api/order/detail/' + state.id)
        .then((res) => {
          proxy.$toast.clear()
          const body = res.data.data

          if (res.data.code == '200') {
            state.orderInfo = body
            if (
              body.zxq_qrcode.url !== '' &&
              body.zxq_qrcode.url !== undefined
            ) {
              state.zxq_qrcode = body.zxq_qrcode
            } else {
              state.zxq_qrcode = ''
            }

            setStore('shop_id', body.shop_id)
            commitAddShopId(store, proxy, body.shop_id)

            isWeiXin() //是否为微信浏览器
            getKefu(body.dis_id, body.reception_id) //获取客服数据

            //知晓群
            if (
              state.zxq_qrcode.drainage_code_type === 'zxq' &&
              state.zxq_qrcode.url
            ) {
              zxqQrCode.value.getQrcode(state.zxq_qrcode.url)
            }
          } else if (res.data.code == '401') {
            console.log('未登录')
          } else {
            proxy.$toast(res.data.message)
          }
        })
        .catch((error) => console.log(error))
    }

    const htSobotInitFunc = (el, data) => {
      let { order_name, thumb, pay_status_val, create_time, amount, order_sn } =
        state.orderInfo
      create_time = create_time.replace(/-/g, '/') // iOS需转成yyyy/MM/dd
      var goods = [{ name: order_name, pictureUrl: encodeURI(thumb) }]
      let orderObj = {
        order: {
          order_status: 0,
          status_custom: pay_status_val, //当order_status=0的时候，取该字段作为订单卡片的状态
          create_time: new Date(create_time).getTime(),
          order_code: order_sn,
          order_url: encodeURI(window.location.href),
          goods_count: 1,
          total_fee: (amount - 0) * 100, //（以分为单位，total_fee=1000相当与total_fee=10.00元，不支持小数）
          goods: JSON.stringify(goods)
        }
      }
      htSobot.init(el, Object.assign(data, orderObj))
    }

    // 获取客服参数
    const getKefu = (dis_id, reception_id) => {
      let url =
        reception_id == undefined
          ? `/api/course/consult/${dis_id}/1`
          : `/api/course/consult/${reception_id}`
      proxy.$http
        .get(url)
        .then((res) => {
          // console.log("客服参数===>", res.data)
          const body = res.data.data

          if (res.data.code == '200' && body !== null) {
            state.kefuData = body

            if (state.kefuData.number) {
              if (state.kefuData.type == '0') {
                htSobotInitFunc('kefu_' + state.orderInfo.id, {
                  service: 'branch',
                  ehr_code: state.kefuData.number,
                  type: 'js',
                  new_window: false,
                  prod: true
                })
                // htSobot.init('kefu', {service: 'branch', ehr_code: state.kefuData.number, type: 'js', new_window: false, prod: true});
              } else if (state.kefuData.type == '1') {
                htSobotInitFunc('kefu_' + state.orderInfo.id, {
                  service: 'business',
                  sys_id: state.kefuData.number,
                  type: 'js',
                  new_window: false,
                  prod: true
                })
                // htSobot.init('kefu', {service: 'business', sys_id: state.kefuData.number, type: 'js', new_window: false, prod: true});
              } else if (state.kefuData.type == '2') {
                htSobotInitFunc('kefu_' + state.orderInfo.id, {
                  service: 'channel',
                  sys_id: state.kefuData.number,
                  type: 'js',
                  new_window: false,
                  prod: true
                })
                // htSobot.init('kefu', {service: 'channel', sys_id: state.kefuData.number, type: 'js', new_window: false, prod: true});
              }
            }
          } else {
            // proxy.$toast(res.data.message);
          }
        })
        .catch((error) => console.log(error))
    }

    // 购买须知
    const bindBuyPop = () => {
      state.buyShow = !state.buyShow
    }

    // 提交订单
    const submit = () => {
      if (!state.is_protocol) {
        proxy.$toast('请勾选付费协议')
        return false
      }
      proxy.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '加载中...'
      })
      let data = {
        code: state.code
        // browser: state.browser,
      }

      // 支付方式 1 微信扫码, 2 微信公众号, 3 微信H5, 4 => 微信小程序, 5 支付宝扫码, 6 支付宝手机网站
      if (state.browser == 1) {
        // 微信支付
        if (state.is_pc == '2') {
          // 移动端支付
          data.pay_way = 2
        } else {
          // pc端支付
          data.pay_way = 1
        }
      } else {
        // 支付宝支付
        if (state.is_pc == '2') {
          // 移动端支付
          data.pay_way = 6
        } else {
          // pc端支付
          data.pay_way = 5
        }
      }

      proxy.$http
        .post('/api/order/detail_pay/' + state.id, data, {
          timeout: 50000
        })
        .then((res) => {
          const body = res.data.data
          proxy.$toast.clear()

          if (res.data.code == '200') {
            if (state.browser == 1) {
              //微信支付
              if (state.is_pc == '2') {
                //移动端支付
                WeixinJSBridge.invoke(
                  'getBrandWCPayRequest',
                  {
                    appId: body.appId, //公众号名称，由商户传入
                    timeStamp: body.timeStamp, //时间戳，自1970年以来的秒数
                    nonceStr: body.nonceStr, //随机串
                    package: body.package,
                    signType: body.signType, //微信签名方式：
                    paySign: body.paySign, //微信签名
                    jsApiList: ['chooseWXPay']
                  },
                  (res) => {
                    // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    if (res.err_msg == 'get_brand_wcpay_request:ok') {
                      paymentTrack(1).then((res) => {
                        window.location.href = '/order'
                      })
                    } else {
                      paymentTrack(0).then((res) => {
                        proxy.$toast('支付失败')
                      })
                    }
                  }
                )
              } else {
                //pc端支付
                state.show_code = true
                state.qrCode = body.qrCode
              }
            } else {
              //支付宝支付
              if (state.is_pc == '2') {
                //移动端支付
                document.querySelector('body').innerHTML = body.qrBody //查找到当前页面的body，将后台返回的form替换掉body内容
                document.forms[0].submit()
              } else {
                //pc端支付
                state.show_code = true
                state.qrCode = body.qrCode
              }
            }
          } else {
            paymentTrack(0).then((result) => {
              proxy.$toast(res.data.message)
            })
          }
        })
        .catch((error) => {
          console.log(error)
          // paymentTrack(0).then(result => {
          //     proxy.$toast(error.data.message);
          // });
        })
    }

    //判断是否是微信浏览器的函数
    const isWeiXin = () => {
      //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase()
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        state.browser = '1'

        // 获取code
        if (state.orderInfo.pay_status == '0') {
          //为支付获取code
          let code = getUrlParam('code')
          let local = window.location.href
          let php_time = time.toString().substring(0, 10)
          let secret = 'getWxCodeFor_purchase.huatu.com'
          let signNew = md5(md5(local + php_time + secret))
          let appid = ''

          // 区分在线和教育公众号appid
          if (
            state.orderInfo.platform == '2' ||
            (state.orderInfo.department == 3 && state.orderInfo.order_type != 5)
          ) {
            // appid = "wx42d6c48d12ecc5a0";
            appid = 'wxb6ffc17b892a2a7b'
          } else {
            appid = 'wxb6ffc17b892a2a7b'
          }

          if (code == null || code == '') {
            window.location.href =
              'https://m.xue.huatu.com/mp/proxy.php?appid=' +
              appid +
              '&time=' +
              php_time +
              '&sign=' +
              signNew +
              '&redirect_url=' +
              encodeURIComponent(local)
          } else {
            state.code = code
          }
        }
      } else {
        state.browser = '2'
      }

      // 判断当前设备是移动端还是PC端
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      if (flag) {
        state.is_pc = 2
      } else {
        state.is_pc = 1
      }
    }

    // 生成二维码
    const qrcode = (qrcode, text) => {
      let qrcodeEle = document.querySelector('#' + qrcode)
      let qrcode2 = new QRCode(qrcodeEle, {
        width: 200,
        height: 200 // 高度
        // text: text // 二维码内容
      })
      qrcode2.makeCode(text)
      var canvas = $('#qrcode').find('canvas').get(0)
      $('#qrcode>img').attr('src', canvas.toDataURL('image/jpg'))
    }

    // 弹窗加载完成
    const onOpened = () => {
      qrcode('qrcode', state.qrCode)
    }

    // pc二维码弹窗
    const bindCodePop = () => {
      state.show_code = false
    }

    // 获取url code参数
    const getUrlParam = (name) => {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      let url = window.location.href.split('#')[0]
      let search = url.split('?')[1]
      if (search) {
        var r = search.substr(0).match(reg)
        if (r !== null) return unescape(r[2])
        return null
      } else {
        return null
      }
    }

    // 勾选协议
    const bindProtocol = () => {
      state.is_protocol = !state.is_protocol
    }

    // 在线客服
    const bindKefu = () => {
      let url = ''
      let title_info = state.orderInfo.order_name
      let label_info = state.orderInfo.amount
      let url_info = encodeURIComponent(window.location.href)
      let tel = userInfo.mobile
      let huatuCode = userInfo.huatuCode

      // 区分售前售后客服
      if (
        (state.orderInfo.pay_status == 1 && state.orderInfo.flag == 0) ||
        (state.orderInfo.pay_status == 1 &&
          state.orderInfo.flag == 1 &&
          state.orderInfo.group_success == 1)
      ) {
        url =
          'https://huatu.sobot.com/chat/h5/v2/index.html?sysnum=c1a2757af422403fbce73e24dccc5160&channelid=21&groupid=0cab09278fd649c4817beae840be1b5d&title_info=' +
          title_info +
          '&label_info=' +
          label_info +
          '&url_info=' +
          url_info +
          '&tel=' +
          tel +
          '&type=3&robotid=6' +
          '&params=' +
          encodeURI('{"huatuCode":"' + huatuCode + '"}')
      } else {
        url =
          'https://huatu.sobot.com/chat/h5/v2/index.html?sysnum=c1a2757af422403fbce73e24dccc5160&channelid=21&groupid=b698f966107a4e6bbedb371a4ebb6385&title_info=' +
          title_info +
          '&label_info=' +
          label_info +
          '&url_info=' +
          url_info +
          '&tel=' +
          tel +
          '&type=4&robotid=6' +
          '&params=' +
          encodeURI('{"huatuCode":"' + huatuCode + '"}')
      }
      // 跳转客服
      // console.log(url)
      window.location.href = url
    }

    // 跳转课程详情
    const toKechDetail = () => {
      // console.log(state.orderInfo);
      let params = {
        dis_id: state.orderInfo.detail_ref_id
      }
      let query = {}
      if (state.orderInfo.detail_ref_id == 0) {
        query.dis_id = state.orderInfo.dis_id
      }
      $router.push({
        name: 'detail',
        params: params,
        query: query
      })

      // $router.push({
      // 	name: 'detail',
      // 	params: {
      // 		dis_id: state.orderInfo.detail_ref_id
      // 	}
      // });
    }

    // 支付成功与否的埋点
    const paymentTrack = (type) => {
      let eventName =
          type === 1
            ? 'HuaTuEducation_m_htxd_PaymentSucceed'
            : 'HuaTuEducation_m_htxd_PaymentFailed',
        params = {
          order_id: state.id,
          course_id: state.orderInfo.dis_id,
          course_title: state.orderInfo.order_name,
          course_source: state.orderInfo.platform,
          teach_way: state.orderInfo.order_type,
          price: state.orderInfo.origin_price,
          real_price: state.orderInfo.amount,
          promoter_phone: '',
          ehr_account: '',
          $url: window.location.href,
          business_unit: state.orderInfo.department
        }
      if (type === 1) {
        params.buy_type = state.course.group_status == 1 ? '拼团' : '普通'
      }
      return sensorTrack({
        eventName,
        params
      })
    }

    // 跳转修改地址
    const changeAddress = () => {
      $router.push({
        path: '/address',
        query: {
          is_back: true,
          from: 'order_detail',
          course_id: state.id
        }
      })
    }

    return {
      state,
      userInfo,
      zxqQrCode,
      getOrderInfo,
      getWuliu,
      bindPop,
      copy,
      getKefu,
      bindBuyPop,
      submit,
      qrcode,
      bindCodePop,
      onOpened,
      getUrlParam,
      bindProtocol,
      bindKefu,
      toKechDetail,
      changeAddress
    }
  }
}
</script>

<style scoped lang="scss">
.order-detail-page {
  height: 100%;
  background: #f8f9fa;
  .page-info {
    background: #ffffff;
    border-radius: 8px;
    margin: 12px 12px 0 12px;
    padding: 15px;
    .info-hd {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .hd-tit {
        width: 249px;
        text-align: start;
      }
      .hd-status {
        font-size: 14px;
        color: #9fa6af;
        line-height: 24px;
      }
      .status-01 {
        color: #fc6c21;
      }
      .status-02 {
        color: #ff2223;
      }
    }
    .hd-number {
      font-size: 12px;
      color: #999999;
      line-height: 17px;
      padding: 10px 0 13px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: none;
      .icon-copy {
        display: block;
        width: 12px;
        height: 12px;
        margin-left: 5px;
        background: url(../../assets/icons/icon-copy.png) center/100% 100%
          no-repeat;
      }
    }
  }
  .page-site {
    background: #ffffff;
    border-radius: 8px;
    margin: 12px 10px 0 10px;
    padding: 15px;
    .site-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      .tit {
        font-size: 13px;
        color: #999999;
        line-height: 18px;
      }
      .tit-01 {
        color: #666666;
      }
      .txt {
        font-size: 13px;
        color: #333333;
        line-height: 18px;
        text-align: end;
      }
    }
  }
  .bt-protocol {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    bottom: 56px;
    left: 0;
    right: 0;
    font-size: 12px;
    color: #333333;
    padding-left: 16px;
    padding-bottom: 6px;
    padding-top: 6px;
    background: #fff;
    .icon-selected {
      display: block;
      width: 14px;
      height: 14px;
      background: url(../../assets/icons/icon-selected.png) center/100%,
        100% no-repeat;
      margin-right: 4px;
    }
    .icon-unselected {
      display: block;
      width: 14px;
      height: 14px;
      background: url(../../assets/icons/icon-unselected.png) center/100%,
        100% no-repeat;
      margin-right: 4px;
    }
  }
  .page-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 8px 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    .lt {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .ft-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-left: 10px;
      .icon-kefu {
        display: block;
        width: 20px;
        height: 20px;
        background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon_kefu.png)
          center/100% 100% no-repeat;
      }
      .icon-wuliu {
        display: block;
        width: 20px;
        height: 20px;
        background: url(../../assets/icons/icon-wuliu.png) center/100% 100%
          no-repeat;
      }
      .txt {
        font-size: 11px;
        color: #333333;
        line-height: 16px;
      }
    }
  }
  .page-pay {
    background: #ffffff;
    border-radius: 8px;
    font-size: 13px;
    margin: 12px 10px 0 10px;
    .item {
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      .lt-tit {
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
      }
      .rt {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .icon-pay {
          display: block;
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
        .icon-wx {
          background: url(../../assets/image/pay/icon-wx.png) center/100%,
            100% no-repeat;
        }
        .icon-zfb {
          background: url(../../assets/image/pay/icon-zfb.png) center/100%,
            100% no-repeat;
        }
      }
      .ipt {
        text-align: end;
      }
    }
  }
  .pop-code {
    width: 350px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .title {
      padding: 15px 0 30px 0;
    }
    .btn {
      margin-top: 30px;
    }
  }
  .btn-wrap {
    display: flex;
    justify-content: right;
    margin-top: 12px;
    margin-right: 10px;
    .btn {
      padding: 0 10px;
      box-sizing: border-box;
      border-radius: 12px;
      font-size: 12px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      margin-left: 8px;
    }
    .btn-01 {
      border: 1px solid #fc6c21;
      color: #fc6c21;
    }
  }
}
</style>
